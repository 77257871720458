<template>
  <div>
    <custom-header></custom-header>
    <div class="container">
      <div class="my-form">
        <!-- <div class="show-content" v-html="detail.description"></div> -->
        <div v-if="type==3">
          <div class="topTitle">
            用户使用协议
          </div>
          <div class="welcomeText">
            欢迎你使用网上生命纪念馆！
          </div>
          <div class="textContainer">
            <div class="erTitle">
              1.特别提醒
            </div>
            <div class="contents">
              <p>1.1为了更好地为您提供服务，请您仔细阅读这份协议。本协议是您与本应用就您登录本应用平台进行注册及使用等所涉及的全部行为所订立的权利义务规范。您在注册过程中点击“注册”等按钮、及注册后登录和使用时，均表明您已完全同意并接受本协议，愿意遵守本协议的各项规则、规范的全部内容，若不同意则可停止注册或使用本应用平台。如您是未成年人，您还应要求您的监护人仔细阅读本协议，并取得他/他们的同意。</p>
              <p>1.2为提高用户的使用感受和满意度，用户同意本应用将基于用户的操作行为对用户数据进行调查研究和分析，从而进一步优化服务。</p>
            </div>
            <div class="erTitle">
              2.服务内容
            </div>
            <div class="contents">
              <p>2.1用户不得利用网上生命纪念馆服务制作、上载、复制、发布、传播或者转载如下内容：
                反对宪法所确定的基本原则的；
                危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
                损害国家荣誉和利益的；
                煽动民族仇恨、民族歧视，破坏民族团结的；
                侮辱、滥用英烈形象，否定英烈事迹，美化粉饰侵略战争行为的；
                破坏国家宗教政策，宣扬邪教和封建迷信的；
                散布谣言，扰乱社会秩序，破坏社会稳定的；
                散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
                侮辱或者诽谤他人，侵害他人合法权益的；
                含有法律、行政法规禁止的其他内容的信息。

                所有用户同意遵守网上生命纪念馆用户协议。
                网上生命纪念馆有权对用户使用网上生命纪念馆的情况进行审查和监督，如用户在使用网上生命纪念馆时违反任何上述规定，网上生命纪念馆或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户张贴的内容、暂停或终止用户使用网上生命纪念馆的权利）以减轻用户不当行为造成的影响。</p>
              <p>2.2本应用服务的具体内容由本应用制作者根据实际情况提供。</p>
              <p>2.3 除非本注册及服务协议另有其它明示规定，本应用所推出的新产品、新功能、新服务，均受到本注册及注册协议规范。本应用仅提供相关的网络服务，除此之外与相关网络服务有关的设备(如个人电脑、手机、及其他与接入互联网或移动网有关的装置)及所需的费用(如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费)均应由用户自行负担。</p>
            </div>
            <div class="erTitle">
              3.使用规则
            </div>
            <div class="contents">
              <p class="indent">3.1 用户帐号注册</p>
              <div class="indenttwo">
                <p>3.1.1使用本应用系统注册的用户，只能使用汉字、英文字母、数字、下划线及它们的组合，禁止使用空格、各种符号和特殊字符，且最多不超过16个字符(8个汉字)注册，否则将不予注册。</p>
                <p>3.1.2使用第三方合作网站登录的用户，只能使用汉字、英文字母、数字、下划线及它们的组合，禁止使用空格、各种符号和特殊字符，且最多不超过14个字符(7个汉字)注册，否则本应用有权只截取前14个字符（7个汉字）予以显示用户帐号（若该用户帐号与应用现有用户帐号重名，系统将随机添加一个字符以示区别），否则将不予注册。</p>
                <p>3.2如发现用户帐号中含有不雅文字或不恰当名称的，网上生命纪念馆保留取消其用户资格的权利。</p>
                <p>3.2.1请勿以党和国家领导人或其他社会名人的真实姓名、字号、艺名、笔名注册；</p>
                <p>3.2.2请勿以国家机构或其他机构的名称注册；</p>
                <p>3.2.3请勿注册不文明、不健康名字，或包含歧视、侮辱、猥亵类词语的帐号；</p>
                <p>3.2.4请勿注册易产生歧义、引起他人误解或其它不符合法律规定的帐号。</p>
                <p>3.3用户帐号的所有权归本应用，用户仅享有使用权。</p>
                <p>3.4用户有义务保证密码和帐号的安全，用户利用该密码和帐号所进行的一切活动引起的任何损失或损害，由用户自行承担全部责任，本应用不承担任何责任。如用户发现帐号遭到未授权的使用或发生其他任何安全问题，应立即修改帐号密码并妥善保管，如有必要，请反馈通知本应用管理人员。因黑客行为或用户的保管疏忽导致帐号非法使用，本应用不承担任何责任。</p>
                <p>3.5 用户承诺对其发表或者上传于本应用的所有信息(即属于《中华人民共和国著作权法》规定的作品，包括但不限于文字、图片、音乐、电影、表演和录音录像制品和电脑程序等)均享有完整的知识产权，或者已经得到相关权利人的合法授权；如用户违反本条规定造成本应用被第三人索赔的，用户应全额补偿本应用的一切费用(包括但不限于各种赔偿费、诉讼代理费及为此支出的其它合理费用)；</p>
                <p>3.6当第三方认为用户发表或者上传于本应用的信息侵犯其权利，并根据《信息网络传播权保护条例》或者相关法律规定向本应用发送权利通知书时，用户同意本应用可以自行判断决定删除涉嫌侵权信息，除非用户提交书面证据材料排除侵权的可能性，本应用将不会自动恢复上述删除的信息；</p>

                <div>
                  <p>(1)不得为任何非法目的而使用网络服务系统；</p>
                  <p>(2)遵守所有与网络服务有关的网络协议、规定和程序；</p>
                  <p>(3)不得利用本应用的服务进行任何可能对互联网的正常运转造成不利影响的行为；</p>
                  <p>(4)不得利用本应用服务进行任何不利于本应用的行为。</p>
                </div>
                <p>3.7如用户在使用网络服务时违反上述任何规定，本应用有权要求用户改正或直接采取一切必要的措施(包括但不限于删除用户上传的内容、暂停或终止用户使用网络服务的权利)以减轻用户不当行为而造成的影响。</p>
              </div>
            </div>
            <div class="erTitle">
              4.责任声明
            </div>
            <div class="contents">
              <p>
                4.1 网上生命纪念馆不能对用户发表的回答或评论的正确性进行保证。
                用户在网上生命纪念馆发表的内容仅表明其个人的立场和观点，并不代表网上生命纪念馆的立场或观点。作为内容的发表者，需自行对所发表内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律及连带责任。网上生命纪念馆不承担任何法律及连带责任。
                网上生命纪念馆不保证网络服务一定能满足用户的要求，也不保证网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作保证。
                对于因不可抗力或知乎不能控制的原因造成的网络服务中断或其它缺陷，网上生命纪念馆不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。
              </p>
            </div>
            <div class="erTitle">
              5.知识产权
            </div>
            <div class="contents">
              <p class="indent">
                5.1网上生命纪念馆是一个公开分享及传播的平台，我们尊重用户的内容，认识到保护知识产权对安泰乐生存与发展的重要性。
              </p>
              <div class="indenttwo">
                <p>
                  1.用户在网上生命纪念馆上发表的全部原创内容，著作权均归用户本人所有。用户可授权第三方以任何方式使用，不需要得到网上生命纪念馆的同意。
                </p>
                <p>2.网上生命纪念馆提供的网络服务中包含的标识、版面设计、排版方式、文本、图片、图形等均受著作权、商标权及其它法律保护，未经相关权利人（含网上生命纪念馆及其他原始权利人）同意，上述内容均不得在任何平台被直接或间接发布、使用、出于发布或使用目的的改写或再发行，或被用于其他任何商业目的。</p>
                <p>3.为了促进知识的分享和传播，用户将其在网上生命纪念馆上发表的全部内容，授予网上生命纪念馆免费的、不可撤销的、非独家使用许可，网上生命纪念馆有权将该内容用于各种形态的产品和服务上，包括但不限于网站以及发表的应用或其他互联网产品。</p>
                <p>4.第三方若出于非商业目的，将用户在网上生命纪念馆上发表的内容转载在网上生命纪念馆之外的地方，应当在作品的正文开头的显著位置注明原作者姓名（或原作者在网上生命纪念馆上使用的帐号名称），给出原始链接，注明「发表于网上生命纪念馆」，并不得对作品进行修改演绎。若需要对作品进行修改，或用于商业目的，第三方应当联系用户获得单独授权，按照用户规定的方式使用该内容。</p>
                <p>5.网上生命纪念馆为用户提供「保留所有权利，禁止转载」的选项。除非获得原作者的单独授权，任何第三方不得转载标注了「禁止转载」的内容，否则均视为侵权。</p>
                <p>6.在网上生命纪念馆上传或发表的内容，用户应保证其为著作权人或已取得合法授权，并且该内容不会侵犯任何第三方的合法权益。如果第三方提出关于著作权的异议，网上生命纪念馆有权根据实际情况删除相关的内容，且有权追究用户的法律责任。给网上生命纪念馆或任何第三方造成损失的，用户应负责全额赔偿。</p>
                <p>7.如果任何第三方侵犯了网上生命纪念馆用户相关的权利，用户同意授权网上生命纪念馆或其指定的代理人代表网上生命纪念馆自身或用户对该第三方提出警告、投诉、发起行政执法、诉讼、进行上诉，或谈判和解，并且用户同意在网上生命纪念馆认为必要的情况下参与共同维权。</p>
                <p>8.网上生命纪念馆有权但无义务对用户发布的内容进行审核，有权根据相关证据结合《侵权责任法》、《信息网络传播权保护条例》等法律法规及网上生命纪念馆社区指导原则对侵权信息进行处理。</p>
              </div>
            </div>
            <div class="erTitle">
              6.协议修改
            </div>
            <div class="contents">
              <p>6.1本应用有权随时修改本协议的任何条款，一旦本协议的内容发生变动，本应用将会在本应用上公布修改之后的协议内容，若用户不同意上述修改，则可以选择停止使用本应用。本应用也可选择通过其他适当方式（比如系统通知）向用户通知修改内容。</p>
              <p>6.2如果不同意本应用对本协议相关条款所做的修改，用户有权停止使用本应用。如果用户继续使用本应用，则视为用户接受本应用对本协议相关条款所做的修改。</p>
            </div>
            <div class="erTitle">
              7.通知送达
            </div>
            <div class="contents">
              <p>7.1本协议项下本应用对于用户所有的通知均可通过网页公告、电子邮件、系统通知、微博管理帐号主动联系、私信、手机短信或常规的信件传送等方式进行；该等通知于发送之日视为已送达收件人。</p>
              <p>7.2用户对于本应用的通知应当通过本应用对外正式公布的通信地址、电子邮件地址等联系信息进行送达。</p>
            </div>
          </div>
        </div>
        <!-- 隐私协议 -->
        <div v-if="type==1">
          <div class="topTitle">
            网上生命纪念馆隐私政策
          </div>
          <div class="mulu">
            目录
          </div>
          <div class="erTitle">
            一、前言
          </div>
          <div class="erTitle">
            二、我们可能收集的信息
          </div>
          <div class="erTitle">
            三、我们如何收集和使用信息
          </div>
          <div class="erTitle">
            四、我们可能分享、转让和披露的信息
          </div>
          <div class="erTitle">
            五、我们如何保留、储存和保护信息
          </div>
          <div class="erTitle">
            六、如何管理您的信息
          </div>
          <div class="erTitle">
            七、第三方服务
          </div>
          <div class="erTitle">
            八、年龄限制
          </div>
          <div class="erTitle">
            九、通知和修订
          </div>

          <div class="itemBox">
            <div class="threeTitle">
              一、前言
            </div>
            <p class="pindent">
              网上生命纪念馆及各关联公司（以下统称“网上纪念馆”或“我们”）一向承诺并致力于保护使用我们产品和服务之用户（以下统称“用户”或“您”）的隐私。您在使用我们的服务时，我们可能会收集和使用您的相关信息。
              本《隐私政策》适用于我们提供的所有服务。当您使用我们任何单项服务时，则视为您同意接受本《隐私政策》以及我们在该单项服务中公示的特定隐私信息类政策条款（以下简称“特定条款”）的保护，在此情况下特定条款与本政策条款同时产生效力。如特定条款与本《隐私政策》条款存在冲突的，则以特定条款为准。如我们提供的某一单项服务不适用本《隐私政策》的，则会在该服务中会以适当方式明示排除适用本《隐私政策》。
              请注意我们会不时地更新《隐私政策》，因此有关的措施会随之变化，更新后的《隐私政策》自公示之日起7日后生效。请您定期光顾本页面，最新版本《隐私政策》始终保持了解。在阅读完本政策之后，如您对本《隐私政策》或与本《隐私政策》相关的事宜有任何问题，您可通过安泰乐提供的各种联系方式与我们联系。
              您使用或继续使用网上生命纪念馆服务，都表示您同意我们按照本《隐私政策》收集、使用、储存和分享您的信息。
            </p>
          </div>

          <div class="itemBox">
            <div class="threeTitle">
              二、我们可能收集的信息
            </div>
            <p class="pindent">
              （一）有关个人身份的信息：
              当您使用网上生命纪念馆服务时，我们可能收集和汇总或要求您提供有关个人身份的信息，例如个人身份证明（包括身份证、护照、驾驶证等）；生日、籍贯、性别、兴趣爱好、个人联系号码、面部特征；个人财产信息（交易记录、余额、优惠券、银行卡信息等）；网络身份标识信息（包括系统账号、IP地址、电子邮箱地址及与前述有关的密码、口令、口令保护答案等）；通讯信息；个人上网记录和日志信息（包括网站浏览记录、软件使用记录、点击记录、操作日志等）；设备信息（包括设备型号、设备MAC地址、操作系统类型、设备设置）；软件列表唯一设备识别码（如IMEI/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息等在内的描述个人常用设备基本情况的信息）；位置信息（包括行程信息、精准定位信息、住宿信息、经纬度等）。
              我们收集您的信息主要是为了您和其他用户能够更容易和更满意地使用我们的服务。我们的目标是向所有的用户提供安全、便捷的上网体验。而这些信息有助于我们实现这一目标。
              请注意，如您在网上生命纪念馆服务中其他用户可见的公开区域内上传或发布的信息中，以及您对其他人上传或发布的信息作出的回应中公开您的个人信息，该等信息可能会被他人收集并加以使用。当您发现其他用户不正当地收集或使用您或他人的信息时，请用我们提供的任何联系方式联系我们。
            </p>
            <p class="pindent">
              （二）与个人身份无关的信息：
              当您使用网上生命纪念馆服务系统时，我们可能收集和汇总诸如用户的来源途径、访问顺序等信息。
            </p>
          </div>

          <div class="itemBox">
            <div class="threeTitle">
              三、我们如何收集和使用信息
            </div>
            <p class="pindent">
              （一）.我们将通过以下途径收集和获得您的信息：
            </p>
            <p class="fourTitle">
              1、您提供的信息。例如：
            </p>
            <p class="fiveTitle">
              （1）您在注册网上生命纪念馆服务系统的帐号或使用网上生命纪念馆服务时，向我们提供的信息；
            </p>
            <p class="fiveTitle">
              （2）您通过网上生命纪念馆服务向其他方提供的共享信息，以及您使用网上生命纪念馆服务时所储存的信息。
            </p>
            <p class="pindent">
              （二）COOKIES、日志档案和WEBBEACON：
            </p>
            <p class="foursTitle">
              我们或我们的合作伙伴可能通过COOKIES和WEB BEACON获取和使用您的信息，并将该等信息储存为日志信息。
              通过使用COOKIES，我们向用户提供简单易行并富有个性化的网络体验。一个COOKIES是少量的数据，它们从一个网络服务器送至您的浏览器并存在计算机硬盘上。我们使用COOKIES是为了让其用户可以受益。比如，为使得网上生命纪念馆虚拟社区的登录过程更快捷，您可以选择把用户名存在一个COOKIES中。这样下次当您要登录网上生命纪念馆的服务时能更加方便快捷。COOKIES能帮助我们确定您连接的页面和内容，您在特定服务上花费的时间和您所选择的网上生命纪念馆服务。
              COOKIES使得我们能更好、更快、更个性化地为您服务。
              我们使用自己的COOKIES和WEBBEACON，用于以下用途：
            </p>
            <p class="fiveTitle">
              （1）记住您的身份。例如：COOKIES和WEB BEACON有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供有关您的喜好或其他信息；
            </p>
            <p class="fiveTitle">
              （2）分析您使用我们服务的情况。我们可利用COOKIES和WEB BEACON来了解您使用网上生命纪念馆服务进行什么活动、或哪些服务最受欢迎；
            </p>
            <p class="fiveTitle">
              （3）广告优化。COOKIES和WEB BEACON有助于我们根据您的信息，向您提供与您相关的广告而非进行普遍的广告投放。
              我们为上述目的使用COOKIES和WEB BEACON的同时，可能将通过COOKIES和WEB BEACON收集的非个人身份信息汇总提供给广告商和其他伙伴，用于分析您和其他用户如何使用我们的服务并用于广告服务。
              网上生命纪念馆服务上可能会有广告商和其他合作方放置的COOKIES和WEB BEACON。这些COOKIES和WEB BEACON可能会收集与您相关的非个人身份信息，以用于分析用户如何使用该等服务、向您发送您可能感兴趣的广告，或用于评估广告服务的效果。这些第三方COOKIES和WEB BEACON收集和使用该等信息不受本《隐私政策》约束，而是受到其自身的个人信息保护声明约束，我们不对第三方的COOKIES或WEB BEACON承担责任。
            </p>
            <p class="pindent">
              （三）我们会出于以下目的，收集和使用您的信息：
            </p>
            <p class="fiveTitle">
              1、帮助您完成注册
              为便于我们为您提供服务，您需要提供基本注册信息，例如手机号码、电子邮箱地址等，并创建您的用户名和密码。
            </p>
            <p class="fiveTitle">
              2、向您提供商品或服务
              我们所收集和使用的信息是为您提供服务的必要条件，如缺少相关信息，我们将无法为您提供服务的核心内容，例如：
            </p>
            <p class="sixTitle">
              （1）在部分服务项目中，为便于向您交付商品或服务，您需提供收货人个人身份信息、姓名、收货地址、邮政编码、收货人、联系电话、支付状态等信息。如果您拒绝提供此类信息，商家将无法完成相关交付服务。如您通过网上生命纪念馆为其他人订购商品或服务，您需要提供该实际订购人的前述信息。向我们提供该实际订购人的前述信息之前，您需确保您已经取得其授权同意。
            </p>
            <p class="sixTitle">
              （2）为展示您账户的订单信息，我们会收集您在使用网上生命纪念馆过程中产生的订单信息用于向您展示及便于您对订单进行管理
            </p>
            <p class="sixTitle">
              （3）当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。
            </p>
            <p class="sixTitle">
              （4）为确认交易状态及为您提供售后与争议解决服务，我们会通过您基于交易所选择的交易对象、支付机构、物流公司等收集与交易进度相关的您的交易、支付、物流信息，或将您的交易信息共享给上述服务提供者。
            </p>
            <p class="fiveTitle">
              3、向您推送消息
            </p>
            <p class="sixTitle">
              （1）为您展示和推送商品或服务。我们可能使用您的信息，您的浏览及搜索记录、设备信息、位置信息、订单信息，提取您的浏览、搜索偏好、行为习惯、位置信息等特征，并基于特征标签通过电子邮件、短信或其他方式向您发送营销信息，提供或推广我们或第三方的商品和服务;
            </p>
            <p class="sixTitle">
              （2）向您发出通知。我们可能在必需时（例如当我们由于系统维护而暂停某一单项服务、变更、终止提供某一单项服务时）向您发出与服务有关的通知。 如您不希望继续接收我们推送的消息，您可要求我们停止推送，例如：在移动端设备中进行设置，不再接收我们推送的消息等；但我们依法律规定或单项服务的服务协议约定发送消息的情形除外。
            </p>
            <p class="fiveTitle">
              4、为您提供安全保障
            </p>
            <p class="sixTitle">
              为确保您身份真实性、向您提供更好的安全保障，您可以向我们提供身份证明等个人敏感信息以完成实名认证。
              除身份验证外，我们可能将您的信息用于客户服务、安全防范、诈骗监测、存档和备份等用途，确保我们向您提供的服务的安全性；我们可能使用或整合我们所收集的您的信息，以及我们的合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
            </p>
            <p class="fiveTitle">
              5、改进我们的服务
            </p>
            <p class="sixTitle">
              我们可能将通过某一项服务所收集的信息，用于我们的其他服务。例如，在您使用某一项网上生命纪念馆服务时所收集的您的信息，可能在另一项服务中用于向您提供特定内容或向您展示与您相关的、而非普遍推送的信息；我们可能将您的信息用于软件更新
              您了解并同意，在收集您的信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别您的身份，在此情况下我们有权使用已经去标识化的信息，对用户数据库进行分析并予以商业化的利用
              您制作上传的内容可自行设置隐私和公开，设置公开的内容，如文章、图片、音频、视频等，我们将视为可公开在电脑端官网、手机端网站等平台宣传的内容，在法律允许范围内最终解释权归我方所有
              如果我们将您的信息用于本《隐私政策》中未载明的其他用途，会事先征求您同意。
            </p>
            <p class="fiveTitle">
              6、征得授权同意的例外
              根据相关法律法规规定，以下情形中收集您的信息无需征得您的授权同意：
            </p>
            <p class="sixTitle">
              （1）与国家安全、国防安全有关的；
            </p>
            <p class="sixTitle">
              （2）与公共安全、公共卫生、重大公共利益有关的；；
            </p>
            <p class="sixTitle">
              （3）与犯罪侦查、起诉、审判和判决执行等有关的；
            </p>
            <p class="sixTitle">
              （4）出于维护信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人 同意的；
            </p>
            <p class="sixTitle">
              （5）所收集的信息是您自行向社会公众公开的；
            </p>
            <p class="sixTitle">
              （6）从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道；
            </p>
            <p class="sixTitle">
              （7）根据您的要求签订合同所必需的；
            </p>
            <p class="sixTitle">
              （8）用于维护网上生命纪念馆服务的安全稳定运行所必需的，例如发现、处置产品或服务的 故障；
            </p>
            <p class="sixTitle">
              （9）为合法的新闻报道所必需的；
            </p>
            <p class="sixTitle">
              （10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的信息进行去标识化处理的；
            </p>
            <p class="sixTitle">
              （11）法律法规规定的其他情形。
            </p>

          </div>

          <div class="itemBox">
            <div class="threeTitle">
              四、我们可能分享、转让或披露的信息
            </div>
            <p class="pindent">
              （一）分享
            </p>
            <p class="fourTitle">
              除以下情形外，未经您同意，我们不会与除我们之外的任何第三方分享您的信息：
            </p>
            <p class="fiveTitle">
              1、向您提供我们的服务。我们可能向合作伙伴及其他第三方分享您的信息，以实现您需要的核心功能或提供您需要的服务，例如：向物流服务商提供对应的订单信息；
            </p>
            <p class="fiveTitle">
              2、维护和改善我们的服务。我们可能向合作伙伴及其他第三方分享您的信息，以帮助我们为您提供更有针对性、更完善的服务，例如：代表我们发出电子邮件或推送通知的通讯服务提供商等；
            </p>
            <p class="fiveTitle">
              3、实现本《隐私政策》第二条“我们如何收集和使用信息”部分所述目的；
            </p>
            <p class="fiveTitle">
              4、履行我们在本《隐私政策》或我们与您达成的其他协议中的义务和行使我们的权利；
            </p>
            <p class="fiveTitle">
              5、向委托我们进行推广的合作伙伴等第三方共享，但我们仅会向这些委托方提供推广的覆盖面和有效性的信息，而不会提供可以识别您身份的信息，例如姓名电话号码或电子邮箱；或者我们将这些信息进行汇总，以便它不会识别您个人。
            </p>
            <p class="fiveTitle">
              6、在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，我们可能与其他公司和组织交换信息。不过,这并不包括违反本《隐私政策》中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的信息。
            </p>
            <p class="fiveTitle">
              7、应您合法需求，协助处理您与他人的纠纷或争议；
            </p>
            <p class="fiveTitle">
              8、应您的监护人合法要求而提供您的信息；
            </p>
            <p class="fiveTitle">
              9、根据与您签署的单项服务协议（包括在线签署的电子协议以及相应的平台规则）或 其他的法律文件约定所提供；
            </p>
            <p class="fiveTitle">
              10、基于学术研究而提供；
            </p>
            <p class="fiveTitle">
              11、基于符合法律法规的社会公共利益而提供。我们仅会出于合法、正当、必要、特定、明确的目的共享您的信息。对我们与之共享信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本《隐私政策》以及其他任何相关的保密和安全措施来处理信息。
            </p>
            <p class="fiveTitle">
              8、应您的监护人合法要求而提供您的信息；
            </p>
            <p class="pindent">
              （二）转让
            </p>
            <p class="fiveTitle">
              随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而您的信息有可能作为此类交易的一部分而被转移。我们会要求新的持有您信息的公司、 组织继续受本《隐私政策》的约束，否则,我们将要求该公司、组织重新向您征求授权同意。
            </p>
            <p class="pindent">
              （三）披露
            </p>
            <p class="fiveTitle">
              我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的 信息：
            </p>
            <p class="sixTitle">
              1、根据您的需求，在您明确同意的披露方式下披露您所指定的信息；
            </p>
            <p class="sixTitle">
              2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下， 我们可能会依据所要求的信息类型和披露方式披露您的信息。在符合法律法规的前提下，当 我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票 或调查函。
            </p>
          </div>

          <div class="itemBox">
            <div class="threeTitle">
              五、我们如何保留、储存和保护信息
            </div>
            <p class="pindent">
              （一）我们仅在本《隐私政策》所述目的所必需期间和法律法规要求的时限内保留您的 信息。
            </p>
            <p class="pindent">
              （二）我们在中华人民共和国境内运营中收集和产生的信息，存储在中国境内。
            </p>
            <p class="fourTitle">
              我们将采取以下手段保护您的信息：
            </p>
            <p class="sixTitle">
              1.数据安全技术措施
              我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。网络服务采取了多种加密技术，来保护您的信息。
            </p>
            <p class="sixTitle">
              1、根据您的需求，在您明确同意的披露方式下披露您所指定的信息；
            </p>
            <p class="sixTitle">
              2.我们为保护信息采取的其他安全措施我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范信息的存储和使用。加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护信息重要性的认识。
            </p>
            <p class="sixTitle">
              3.我们仅允许有必要知晓这些信息的员工、合作伙伴访问您的信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与我们的合作关系。
            </p>
            <p class="sixTitle">
              4.我们会采取一切合理可行的措施，确保未收集无关的信息。
            </p>
            <p class="sixTitle">
              5.互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂 密码，并注意保护您的信息安全。</p>
            <p class="sixTitle">
              6.互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的 安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
            </p>
            <p class="sixTitle">
              7.安全事件处置
              在通过网上生命纪念馆服务与第三方进行沟通或购买商品及服务时，您不可避免的要向交易对 方或潜在的交易对方披露自己的信息，如联络方式或者邮政地址等。请您妥善保护自己的信 息，仅在必要的情形下向他人提供。为应对信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。在不幸发生信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的 基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建 议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等 方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我 们还将按照监管部门要求，主动上报信息安全事件的处置情况。
              请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施， 也无法始终保证信息百分之百的安全。您需要了解，您接入网上生命纪念馆所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。
            </p>
          </div>

          <div class="itemBox">
            <div class="threeTitle">
              六、如何管理您的信息
            </div>
            <p class="pindent">
              （一）访问、更新和删除
            </p>
            <p class="fourTitle">
              我们鼓励您更新和修改您的信息以使其更准确有效。您能通过网上生命纪念馆访问您的信息，并根据对应信息的管理方式自行完成或要求我们进行修改、补充和删除。我们将采取适 当的技术手段，尽可能保证您可以访问、更新和更正自己的信息或使用安泰乐服务时提供 的其他信息。在访问、更新、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障信息安全。
            </p>
            <p class="pindent">
              （二）公开与分享
            </p>
            <p class="fourTitle">
              我们的多项服务可让您不仅与您的社交网络、也与使用该服务的所有用户公开分享您的相关信息，例如，您在网上生命纪念馆中所上传或发布的信息、您对其他人上传或发布的信息作出的回应，在不特定用户可见的公开区域内上传或公布您的个人信息，以及包括与这些信息有关的位置数据和日志信息。只要您不删除您所公开或共享的信息，有关信息可能一直留存在公众领域；即使您删除共享信息，有关信息仍可能由其他用户或不受我们控制的第三方独立地缓存、复制或储存，或由其他用户或该等第三方在公众领域保存。如您将信息通过上述渠道公开或共享，由此造成您的信息泄露，我们不承担责任。因此，我们提醒并请您慎重考虑是否通过上述渠道公开或共享您的信息。
            </p>
            <p class="pindent">
              （三）注销
            </p>
            <p class="fourTitle">
              在符合安泰乐的服务协议约定条件及国家相关法律法规规定的情况下，您的该帐号可能被注销或删除。当帐号注销或被删除后，与该帐号相关的全部服务资料和数据将依照服务协议约定删除或处理。
            </p>
            <p class="pindent">
              （四）改变您授权同意的范围
            </p>
            <p class="fourTitle">
              您总是可以选择是否披露信息及披露何种信息。有些信息是使用安泰乐服务所必需的，但大多数其他信息的提供是由您决定的。您可以通过删除信息、关闭设备功能等方式改变您授权我们继续收集信息的范围或撤回您的授权。当您撤回授权后，我们无法继续为您提供撤回授权所对应的服务，也不再处理您相应的信息。但您撤回授权的决定，不会影响此前基于您的授权而开展的信息处理。
            </p>
            <p class="pindent">
              （五）有关敏感信息的提示
            </p>
            <p class="fourTitle">
              某些信息因其特殊性可能被认为是敏感信息，例如您的种族、宗教、个人健康和医疗信息等，以及身份证明文件、个人生物识别信息、财产信息、行踪轨迹、未成年人的信息等。请注意，您在安泰乐服务中所提供、上传或发布的内容和信息（例如有关您社交活动的照片或信息），可能会泄露您的敏感信息。您需要谨慎地考虑，是否使用安泰乐服务 披露您的敏感信息。您同意您的敏感信息按本《隐私政策》所述的目的和方式来处理。
            </p>
          </div>

          <div class="itemBox">
            <div class="threeTitle">
              七、第三方服务
            </div>
            <p class="pindent">
              网上生命纪念馆可能链接至第三方提供的社交媒体或其他服务（包括网站或其他服务形式）。包括：
            </p>
            <p class="fourTitle">
              1.您可利用“分享”键将某些内容分享到网上生命纪念馆，或您可利用第三方服务登录未来集市服务。这些功能可能会收集您的信息（包括您的日志信息），并可能在您的电脑装置 COOKIES，从而正常运行上述功能；
            </p>
            <p class="fourTitle">
              2.我们通过广告或我们服务的其他方式向您提供链接，使您可以接入第三方的服务或网站
            </p>
            <p class="fourTitle">
              3.其他接入第三方服务的情形。第三方社交媒体或其他服务由相关的第三方负责运营。您使用该等第三方的社交媒体服务或其他服务（包括您向该等第三方提供的任何信息），须受第三方的服务条款及信息保护声明（而非受本《隐私政策》）约束），您需要仔细阅读其条款。本《隐私政策》仅适用于我们所收集的任何信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，而我们对任何第三方使用由您提供的信息不承担任何责任。
            </p>
          </div>

          <div class="itemBox">
            <div class="threeTitle">
              八、年龄限制
            </div>
            <p class="pindent">
              我们建议：
            </p>
            <p class="fourTitle">
              任何未成年人参加网上活动应事先取得家长或其法定监护人（以下简称”监护人”）的同意。我们将根据国家相关法律法规的规定保护未成年人的相关信息。 我们鼓励并建议父母或监护人指导未成年人使用安泰乐服务。我们建议未成年人的监护人阅读本《隐私政策》，并建议未成年人在提交信息之前寻求父母或监护人的同意和指导。
            </p>

          </div>
          <div class="itemBox">
            <div class="threeTitle">
              九、通知和修订
            </div>
            <p class="pindent">
              我们可能适时修改本《隐私政策》的条款，或修改构成本《隐私政策》的一部分。对于重大变更，我们会提供更显著的通知，您可以选择停止使用我们的服务；在该种情况下，如您仍然继续使用我们的服务的，即表示同意受经修订的本《隐私政策》的约束。任何修改都会将您的满意度置于首位。我们鼓励您在每次使用安泰乐服务时都查阅我 们的隐私政策。
            </p>
            <p class="pindent">
              我们可能在必需时（例如当我们由于系统维护而暂停某一项服务时）发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。
            </p>
            <p class="pindent">
              最后，您必须对您的账号和密码信息负有保密义务。任何情况下，请小心妥善保管。
            </p>

          </div>
        </div>
        <div class="small-btn">
          <el-button
            type="primary"
            @click.native.prevent="handleFinishCommit"
          >已阅读
          </el-button>
        </div>
      </div>
    </div>
    <custom-footer></custom-footer>
  </div>
</template>

<script>
import { getProtocol } from "@/api/index";
export default {
  name: "agreement",
  data() {
    return {
      detail: {
        description: null,
      },
      type: "",
    };
  },

  created() {
    this.init();
  },

  methods: {
    handleFinishCommit(){
       this.$router.push('/')
    },
    init() {
      if (this.$route.query.type) {
        this.type = this.$route.query.type;
        // this.getDetail();
        console.log(this.type);
      }
    },
    getDetail() {
      getProtocol({ type: this.type }).then((res) => {
        console.log(res);
        this.detail = res.data;
      });
    },
  },
  watch: {
    $route: {
      handler: function () {
        this.init();
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style  lang="scss" scoped>
.container {
  width: 1200px;
  margin: 0 auto 30px;
  background: #fff;
  padding: 100px 0 200px 0;
}
.show-content {
  height: 20vw;
  overflow: auto;
  border: 2px solid #e0e0e0;
  padding: 30px;
}
.my-form {
  width: 70%;
  margin: 0 auto;
  .title {
    font-size: 24px;
    color: #020202;
    margin: 30px auto;
    text-align: center;
  }
}
.show-content::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
.show-content::-webkit-scrollbar-track {
  background: #f4f4f4;
  border-radius: 0;
  border-left: 2px solid #e0e0e0;
}
.show-content::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
.show-content::-webkit-scrollbar-thumb:hover {
  background: #c8c8c8;
}
.show-content::-webkit-scrollbar-corner {
  background: #179a16;
}
.topTitle {
  font-size: 36px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  line-height: 30px;
}
.welcomeText {
  font-size: 30px;
  margin-top: 80px;
  margin-bottom: 80px;
  width: 100%;
  text-align: center;
}
.textContainer {
  width: 100%;

  margin: 0 auto;
}
.erTitle {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  margin-top: 40px;
}
.contents {
  line-height: 26px;
  font-size: 16px;
}
.contents p {
  margin-bottom: 15px;
  margin-left: 15px;
}
.indent {
  margin-left: 15px;
  font-weight: 600;
}
.indenttwo {
  margin-left: 30px;
  margin-top: 20px;
}
.indenttwo p {
  margin-bottom: 15px;
}
.mulu {
  font-size: 30px;
  font-weight: 600;
  margin-top: 80px;
  margin-bottom: 40px;
}
.threeTitle {
  font-size: 18px;
  font-weight: 600;
  margin-left: 15px;
}

.pindent {
  margin-top: 20px;
  margin-left: 15px;
  text-indent: 15px;
  font-size: 18px;
  line-height: 25px;
}
.itemBox {
  margin-bottom: 30px;
}
.fourTitle {
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 30px;
}
.fiveTitle {
  font-size: 18px;
  margin-bottom: 10px;
  margin-left: 45px;
}
.foursTitle {
  text-indent: 45px;
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 30px;
}
.sixTitle {
  margin-left: 60px;
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>

